import React, { useEffect, useState } from 'react';
import { ModalSection } from '../../layout/modalSection';
import {
    ADDRESS,
    EMAIL,
    EMAIL_HREF,
    PHONE,
    PHONE_HREF,
    WEBSITE_LOGO,
    WEBSITE_NAME,
  } from '../../config';
  import {
    loader,
    arrowLeft,
      arrowRight,
      btnCover,
      btnEditing,
      btnFormatting,
      btnGhostwriting,
      btnMarketing,
      btnProofreading,
      btnPublishing,
      cta2,
      cta3,
      cta4,
      cta5,
      facebook,
      favIcon,
      footerIcon1,
      footerIcon2,
      footerIcon3,
      heroBg,
      insta,
      linkedin,
      logoAmazon,
      logo,
      ptintest,
      rating1,
      rating2,
      rating3,
      sealsFooter,
      sec1,
      stars,
      testimonial1,
      testimonial2,
      testimonial3,
      testimonial4,
      tick,
      whiteLogo,
      why1,
      why2,
      why3,
      why4,
      why5,
      why6,
      you,
      ftrCard,
      // platform1,
      // platform2,
      // platform3,
      // platform4,
      // platform5,
      // platform6,
      // platform7,
      // platform8,
      // platform9,
      // platform10,
  } from '../../assets/images';

export const Cookie = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
            <main className='cookie'>
                {/* <Header onOpenModal={onOpenModal} /> */}
                <header class="header-main">
                <div class="top-bar d-lg-block">
                    <div class="container">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-3 col-6 col-md-5 lg-wdt1">
                                <div class="secondlogo">
                                    <a href="javascript:;">
                                        <img class="img-fluid" src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-7 col-6 col-md-7 lg-wdt2">
                                <ul class="top-info-list">
                                    <li>
                                        <a href={PHONE_HREF}><i class="fa fa-phone" aaria-hidden="true"></i>
                                            <span>{PHONE}</span> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" class="chat liveChatButton "><i class="fas fa-comments"></i>
                                            <span>Live
                                                Chat</span></a>
                                    </li>
                                    <li><a href="javascript:;" class="btn-main btn-style-1" onClick={onOpenModal}>Let's Get started</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
                <div class="px-4 py-5 my-1 text-center">
                    <h1 class="display-5 fw-bold">Cookies Policy</h1>
                    <div class="col-lg-6 mx-auto">
                        <div class="para">
                            <p>The Cookies Policy assists both you and us in keeping your information private. In addition, our
                                privacy
                                policy will assist you in comprehending the steps we take to protect your privacy both before and
                                after the
                                work process.   
                            </p>
                        </div>
                    </div>
                </div>
                <section class="main-privacy relative-header pb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="sechead">
                                    <h3 class="montfont">Cookies Policy </h3>
                                    <div class="red-borders"></div>
                                </div>
                                <div class="terms-p mt-4">
                                    <h3>Overview</h3>
                                    <p><br />
                                        We use data collection files on our website called “Cookies” that help us in analyzing the
                                        website flow,
                                        and are necessary for the functionality of the website. Without cookies, certain areas of
                                        the website
                                        will not function, making cookies essential for the complete operations of the website.
                                        Moreover,
                                        cookies help us in remembering the users’ information and interests, allowing us to make the
                                        website
                                        usage more efficient and display relevant content to the users.</p>
                                    <p>Cookies policy is for the visitors of our website, it explains what cookies are and how are
                                        they used.
                                        Our visitors should read the policy in order to have an understanding of the types of
                                        cookies we use,
                                        why we use them and how they help us in providing a better browsing experience to our users.
                                    </p>
                                </div>


                                <div class="terms-p mt-4">
                                    <h3>Cookies</h3>
                                    <p><br />
                                        Cookies are small text file placed on the hard drive of the users’ devices including
                                        computer, tablet,
                                        laptop and mobile phones when they visit our website. Cookies are positioned to make the
                                        users’ browsing
                                        experience better by providing them personalized and relevant content.</p>
                                </div>
                                <div class="terms-p mt-4">
                                    <h3>Types of Cookies</h3>
                                    <p><br />
                                        Session cookies and Persistent cookies are the two main types of cookies. Persistent
                                        Cookies remain on the users devices for a longer period of time or until manually removed
                                        by the user. These cookies are activated upon every visit to the website. Whereas, Session
                                        Cookies are temporary and are only saved while the users stay on the website. These cookies
                                        are deleted as soon as the browser window is closed and the particular session ends. Cookies
                                        have the
                                        following four functions and can be categorized as below:</p>
                                </div>
                                <div class="terms-p mt-4">
                                    <ul>
                                        <li> <h3>Strictly Necessary Cookies</h3> are essential for our website to perform
                                            basic
                                            operations. Without these cookies various parts of the website will not be accessible,
                                            leading to
                                            the user not being able to experience the complete functionality of the website.</li>
                                        <br />
                                        <li><h3>Performance Cookies</h3> are used to collect statistical data of the
                                            visitor’s usage of
                                            the website. These cookies do not contain personal information and are only used for
                                            improving the
                                            users’ browsing experience on the website.</li>
                                        <br />
                                        <li><h3>Functional Cookies</h3> allows the website to remember the users’
                                            preferences while
                                            their visit on the website. These preferences are stored and help in providing a
                                            personalized
                                            experience to the users, upon their return to the website.</li>
                                        <br />
                                        <li><h3>Targeting Cookies</h3> are used to collect information related to the
                                            visitors’
                                            interests and display more relevant content in order to enhance their browsing
                                            experience. Without
                                            the use of these cookies we will not be able to advertise content of the users’ interest
                                            to them.
                                        </li>
                                    </ul>
                                </div>
                                <div class="terms-p mt-4">
                                    <h3>Purpose of Placing Cookies</h3>
                                    <p class="text-left"><br />We use cookies for various purposes. Some cookies are essential for
                                        operational and technical purposes
                                        where as others enable us to provide a personalized experience for visitors.</p>
                                    <ul>
                                        <li>To speed up users’ future experience on the website by storing information and using it
                                            upon their
                                            return.</li>
                                        <br />
                                        <li>To give users a unique browsing experience by tracking their interests and displaying
                                            relevant
                                            information.</li>
                                        <br />
                                        <li>To analysis page flow usage of our website, allowing us to monitor and improve our
                                            performance.</li>
                                        <br />
                                        <li>To maintain the security and integrity and allow users to browse secure areas of the
                                            website.</li>
                                    </ul>
                                </div>
                                <div class="terms-p mt-4">
                                    <h3>Managing and Deleting Cookies</h3>
                                    <p class="text-left"><br />Users can manage or opt out of having cookies by changing the setting
                                        in the Manage Cookies Tab on their
                                        browser. Managing or disallowing cookies is dependent upon the type of browser being used.
                                        By
                                        disallowing or blocking cookies users’ may be unable to access certain areas of the website,
                                        therefore,
                                        it is not recommended in order to experience the website in its complete functional
                                        condition.</p>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="footer-main">

            <div class="footer-last">
                <div class="container">
                    <div class="row row align-items-center">
                        <div class="col-lg-6">
                            <div class="copyright">
                                <p>Copyright © 2024, {WEBSITE_NAME} . All Rights
                                    Reserved.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 ftr-card">
                            <img src={ftrCard} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
                <ModalSection open={open} onCloseModal={onCloseModal} />
            </main>
    );
};
