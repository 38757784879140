import React from "react";
import Slider from "react-slick";
import {
    arrowLeft,
      arrowRight,
      btnCover,
      btnEditing,
      btnFormatting,
      btnGhostwriting,
      btnMarketing,
      btnProofreading,
      btnPublishing,
      cta2,
      cta3,
      cta4,
      cta5,
      facebook,
      favIcon,
      footerIcon1,
      footerIcon2,
      footerIcon3,
      heroBg,
      insta,
      linkedin,
      logoAmazon,
      logo,
      ptintest,
      rating1,
      rating2,
      rating3,
      sealsFooter,
      sec1,
      stars,
      testimonial1,
      testimonial2,
      testimonial3,
      testimonial4,
      tick,
      whiteLogo,
      why1,
      why2,
      why3,
      why4,
      why5,
      why6,
      you,
  } from '../../assets/images'

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };
  return (
    <div className="slider-container">
        <Slider {...settings}>
            <div class="testimonial-slide">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={testimonial1} alt="" class="testimonial-img img-fluid lazy"/>
                    </div>
                    <div class="col-md-5">
                        <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                        <p><strong>Getting your book published is very…</strong></p>
                        <p>Getting your book published is very challenging. Finding the right publisher is even more
                            so. I did extensive research Finding the one that met my needs. I finally settled on
                            Amazon Books Publishing Expert. My project manager was Eminey. After working through some
                            initial problems, with her assistance they produced a book of high quality. She did a
                            fine job following up when needed. I would use them again.</p>
                        <p class="fw-bold">－ David Pearsall</p>
                    </div>
                </div>
            </div>
            <div class="testimonial-slide">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={testimonial2} alt="" class="testimonial-img img-fluid lazy"/>
                    </div>
                    <div class="col-md-5">
                        <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                        <p><strong>solid experience</strong></p>
                        <p>Working with Amazon Books Publishing Expert was an interesting experience. I had no idea what
                            I
                            was doing when I decided I wanted to publish. They streamlined the process and made it
                            easy, there were a couple of snags but nothing major. I worked with Kevin, he was always
                            available be i through email or phone call.</p>
                        <p>One thing id give them to improve would be to explain the process more, as a first timer
                            I dint necessarily know what to ask it would've benefited me to actually know the
                            process. Solid experience though</p>
                        <p class="fw-bold">－ Taurean</p>
                    </div>
                </div>
            </div>
            <div class="testimonial-slide">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={testimonial3} alt="" class="testimonial-img img-fluid lazy"/>
                    </div>
                    <div class="col-md-5">
                        <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                        <p><strong>A guiding professional hand when it was needed.How do I get my stories ready for
                                the…</strong></p>
                        <p>How do I get my stories ready for the public? How do I make sure they are told as I
                            hoped? Can someone walk me through the steps and challenges so my hope is actualized? I
                            turned to Amazon Books Publishing Expert and met with the support and expertise I needed.
                        </p>
                        <p>Ken and his team listened to my book cover ideas and translated them to a cover that
                            expressed the heart and spirit I sought to convey.</p>
                        <p>The Amazon Books Publishing Expert team proofed the stories and brought from Title Page to
                            Notes
                            on the Author to the high professional standard I required.</p>
                        <p>Guidance on issues such as photo selection and quality expectations were available with a
                            simple note or phone call.</p>
                        <p>Most important to me is that Ken made my nervous worries disappear with his clear
                            guidance.</p>
                        <p>This support gives me confidence to write another.</p>
                        <p class="fw-bold">－ John W Gehring</p>
                    </div>
                </div>
            </div>
            <div class="testimonial-slide">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={testimonial4} alt="" class="testimonial-img img-fluid lazy"/>
                    </div>
                    <div class="col-md-5">
                        <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                        <p><strong>I just received my book cover completed…</strong></p>
                        <p>I just received my book cover completed by Kevin Williams after just a couple days of
                            ordering my package. Kevin did the most amazing cover design that I have ever seen on
                            any book jacket before. I have used 7 other other companies before
                            Amazon Books Publishing Expert and I will never again use another company but
                            Amazon Books Publishing Expert again, they
                            are truly amazing. I have been trying to get other designers to see my vision for twenty
                            years and Kevin Williams nailed it and brought my vision to life. I am so pleased. Thank
                            you Amazon Books Publishing Expert for presenting opportunities of excellent quality work at
                            budget prices during Americas recession. Thank you Kevin Williams for high quality and
                            skilled artistic talent that honors my writing with a master piece cover presentation.
                            Cheri Potter</p>
                        <p class="fw-bold">－ Cheri Potter</p>
                    </div>
                </div>
            </div>
        </Slider>
    </div>
    
  );
}