import React from "react";
import Slider from "react-slick";

export default function StepSlider() {
  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    // cssEase: 'linear',
    arrows: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
    ]
  };
  return (
    <div className="slider-container">
<Slider {...settings}>
        <div class="px-3 pb-3">
            <div class="step">
                <div class="step-number">
                    <svg xmlns="http://www.w3.org/2000/svg" width="39.74" height="142.659"
                        viewbox="0 0 39.74 142.659">
                        <path id="Path_152" data-name="Path 152"
                            d="M2.612,20.546c17.322,0,21.2-8.355,24.659-17.934H42.352V145.27H19.934V36.442H2.612Z"
                            transform="translate(-2.612 -2.612)" fill="#ff9a00"></path>
                    </svg>
                </div>
                <h3>Discussion </h3>
                <p>Our process starts with a discussion. First, our professional team listens to the author to
                    know their needs, priorities, and goals for the book. Then discuss their ideas and provide
                    direction to make sensible decisions. </p>
            </div>
        </div>
        <div class="px-3 pb-3">
            <div class="step">
                <div class="step-number">
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.211" height="142.919"
                        viewbox="0 0 66.211 142.919">
                        <path id="Path_148" data-name="Path 148"
                            d="M32.5,20.185c-7.065,0-11.505,3.835-11.505,13.929V49.255H0V35.527C0,12.919,11.3,0,33.105,0S66.211,12.919,66.211,35.527C66.211,79.937,22,96.489,22,119.7a15.343,15.343,0,0,0,.2,3.029H64.192v20.186H0V125.558C0,83.974,44,77.111,44,36.335c0-12.718-4.44-16.15-11.505-16.15"
                            fill="#ff9a00"></path>
                    </svg>
                </div>
                <h3>Preparation Of The Manuscript</h3>
                <p>Once the author has completed the manuscript, our experts do an in-depth review and make it
                    meets the book publishing standards. We offer complete book publishing services, including
                    editing, proofreading, and formatting to make sure your manuscript is picture perfect.</p>
            </div>
        </div>
        <div class="px-3 pb-3">
            <div class="step">
                <div class="step-number">
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.213" height="144.534"
                        viewbox="0 0 66.213 144.534">
                        <path id="Path_149" data-name="Path 149"
                            d="M44.01,36.336c0-12.718-4.442-16.15-11.508-16.15S20.994,24.022,20.994,34.114V43.2H0V35.528C0,12.92,11.305,0,33.108,0S66.213,12.92,66.213,35.528v3.633c0,15.141-4.845,24.629-15.543,29.069,11.1,4.846,15.543,15.342,15.543,29.674v11.1c0,22.608-11.305,35.527-33.105,35.527S0,131.615,0,109.007V97.3H20.994v13.122c0,10.093,4.441,13.928,11.508,13.928S44.01,120.917,44.01,108.4V97.3c0-13.122-4.442-17.966-14.536-17.966H22V59.145h8.682c8.274,0,13.324-3.632,13.324-14.937Z"
                            fill="#ff9a00"></path>
                    </svg>
                </div>
                <h3>Designing </h3>
                <p>The design of a book is important to its success. Our creative designers collaborate with
                    authors to design book covers that accurately reflect their content. They also took care of
                    the interior layout, making the book visually appealing and reader-friendly.</p>
            </div>
        </div>
        <div class="px-3 pb-3">
            <div class="step">
                <div class="step-number">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                        viewbox="0 0 66.84 121.17">
                        <path class="cls-1"
                            d="M2.61,81,35.74,2.61H55.61V81h8.62V97.52H55.61v21H37.72v-21H2.61Zm35.11,0V37.4L19.34,81Z">
                        </path>
                        <path class="cls-1"
                            d="M55.61,2.61V81h8.62V97.52H55.61v21H37.72v-21H2.61V81L35.74,2.61H55.61M19.34,81H37.72V37.4L19.34,81M58.23,0H34l-.68,1.6L.21,79.94,0,80.43v19.7H35.11v21H58.23v-21h8.61V78.34H58.23V0ZM23.28,78.34l11.83-28v28Z">
                        </path>
                        <path class="cls-1"
                            d="M2.61,81,35.74,2.61H55.61V81h8.62V97.52H55.61v21H37.72v-21H2.61Zm35.11,0V37.4L19.34,81Z">
                        </path>
                    </svg>
                </div>
                <h3>Printing and Distribution </h3>
                <p>Once the design process is completed, our team will print and distribute. We use cutting-edge
                    technology and techniques to create high-quality books. We also manage the distribution to
                    make the book available in paper and digital format.</p>
            </div>
        </div>
    </Slider>
    </div>
    
  );
}