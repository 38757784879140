// Default CSS for the main brand
// import './App.css';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AppRoutes from "./routes";
import usePageTitle from './usePageTitle';

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  usePageTitle();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/cookie-policy')) {
      import('./module/cookie/index.css');  // Dynamically import Cookie Policy CSS
    } else {
      import('./App.css');  // Default CSS for the brand pages
    }
  }, [location.pathname]);

  return (
    <AppRoutes />
  );
}

export default App;
