import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import {
  arrowLeft,
    arrowRight,
    btnCover,
    btnEditing,
    btnFormatting,
    btnGhostwriting,
    btnMarketing,
    btnProofreading,
    btnPublishing,
    cta2,
    cta3,
    cta4,
    cta5,
    facebook,
    favIcon,
    footerIcon1,
    footerIcon2,
    footerIcon3,
    heroBg,
    insta,
    linkedin,
    logoAmazon,
    logo,
    ptintest,
    rating1,
    rating2,
    rating3,
    sealsFooter,
    sec1,
    stars,
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    tick,
    whiteLogo,
    why1,
    why2,
    why3,
    why4,
    why5,
    why6,
    you,
    loader,
} from '../../assets/images'

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=440477';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonbookspublishingexpert.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  return (
    <Modal show={open} onHide={onCloseModal} aria-labelledby="contained-modal-title-vcenter" centered>
      <div id="quote-form">
            <div class="banner-form">
                <a class="close-mark" href='javascript:;' onClick={onCloseModal}><i class="fa-solid fa-xmark"></i></a>
                <div class="banner-form-inner white-bg">
                    <form class="form_submission main-pop" method="POST" onSubmit={onSubmitFunc}>
                        
                        <h3 class="text-black text-uppercase text-center">Get Free Estimate</h3>
                        <div class="input-box mt-3">
                            <input type="text" placeholder="Name" name="name" required class="form-control" onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                        </div>
                        <div class="input-box mt-3">
                            <input type="email" placeholder="Email" name="email" required class="form-control" onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/>
                        </div>
                        <div class="input-box mt-3">
                            <input type="number" placeholder="Phone Number" name="phone" required
                                class="form-control" onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/>
                        </div>
                        <div class="input-box mt-3 text-center">
                            <textarea name="message" id="msg" cols="30" rows="4" placeholder="Write Message"
                                class="form-control" onChange={(e) =>setFormData({ ...formData,message: e.target.value,})}></textarea>
                        </div>
                        <div class="mt-3 text-right">
                           
                            {loading == true ? (
                                              <div className="loader" style="display: none">
                                                <img alt="loader" src={loader}/>
                                              </div>
                                          ) : (
                                            <button type="submit" value="1"
                                            class="btn-yellow-ochre btn btn-rounded mx-auto w-100 btn-accent">
                                            Get Started
                                        </button>
                                          )
                                      }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Modal>
  );
};