// import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  loader,
  arrowLeft,
    arrowRight,
    btnCover,
    btnEditing,
    btnFormatting,
    btnGhostwriting,
    btnMarketing,
    btnProofreading,
    btnPublishing,
    cta2,
    cta3,
    cta4,
    cta5,
    facebook,
    favIcon,
    footerIcon1,
    footerIcon2,
    footerIcon3,
    heroBg,
    insta,
    linkedin,
    logoAmazon,
    logo,
    ptintest,
    rating1,
    rating2,
    rating3,
    sealsFooter,
    sec1,
    stars,
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    tick,
    whiteLogo,
    why1,
    why2,
    why3,
    why4,
    why5,
    why6,
    you,
    // platform1,
    // platform2,
    // platform3,
    // platform4,
    // platform5,
    // platform6,
    // platform7,
    // platform8,
    // platform9,
    // platform10,
} from '../../assets/images'
import {
    ADDRESS,
    EMAIL,
    EMAIL_HREF,
    PHONE,
    PHONE_HREF,
    WEBSITE_LOGO,
    WEBSITE_NAME,
  } from '../../config';
import StepSlider from '../../components/StepSlider';
import TestimonialSlider from '../../components/TestimonialSlider';
import { ModalSection } from '../../layout/modalSection';
import PlatformSlider from '../../components/PlatformSlider';

function Home() {
    
    useEffect(() => {
    // Initialize the LiveChat script when the component mounts
    window.__lc = window.__lc || {};
    window.__lc.license = 18319443;
    window.__lc.integration_name = "manual_onboarding";
    window.__lc.product_name = "livechat";

    (function (n, t, c) {
        function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
            i(["on", c.call(arguments)]);
        },
        once: function () {
            i(["once", c.call(arguments)]);
        },
        off: function () {
            i(["off", c.call(arguments)]);
        },
        get: function () {
            if (!e._h)
            throw new Error(
                "[LiveChatWidget] You can't use getters before load."
            );
            return i(["get", c.call(arguments)]);
        },
        call: function () {
            i(["call", c.call(arguments)]);
        },
        init: function () {
            var n = t.createElement("script");
            n.async = !0;
            n.type = "text/javascript";
            n.src = "https://cdn.livechatinc.com/tracking.js";
            t.head.appendChild(n);
        },
        };
        !n.__lc.asyncInit && e.init();
        n.LiveChatWidget = n.LiveChatWidget || e;
    })(window, document, [].slice);
    }, []);

    const toggleChat = () => {
    if (window.LiveChatWidget) {
        window.LiveChatWidget.call('maximize'); // Open the chat window
    }
    };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      phone: '',
  });

  const onSubmitFunc = async (e) => {
  e.preventDefault();

  console.log('formData', formData);
  if (formData.phone.length < 9) {
    setErrors(true);
  } else {
    const currentUrl = window.location.href;
    const protocol = window.location.protocol; // "https:"
    const hostname = window.location.hostname; // "creativeghostwriters.org"

    // Construct the base URL
    const baseUrl = `${protocol}//${hostname}`;
    const queryStringFormData = new URLSearchParams(formData).toString();

    let finalReq = {
      ...formData,
      source: `${currentUrl}${queryStringFormData}`,
      domain: baseUrl,
      lead_url: currentUrl,
      url: `${currentUrl}${queryStringFormData}`,
    };
    try {
      setLoading(true);
      const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=440477';
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const formDataString = new URLSearchParams(finalReq).toString();

      const response = await axios.post(apiUrl, formDataString, config);
      if (response.status == 200) {
        // toast.success("Thank you for filling out the information");
        // console.log(response.data.data.id);
        const queryString = new URLSearchParams(
          response.data.data
        ).toString();
        console.log('queryString', queryString);
        setLoading(false);
        setErrors(false);
        setFormData({
          name: '',
          email: '',
          message: '',
          phone: '',
        });
        // naviagte(`/thankyou?${queryString}`)
        window.location.href = `https://amazonbookspublishingexpert.com/thankyou?${queryString}`;
      }
      console.log('responseresponseresponse', response);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
      setOpen(true);
  };

  const onCloseModal = () => {
      setOpen(false);
  };
  return (
    <div className="App">
      <header class="bg-accent">
        <nav class="navbar navbar-expand-lg top-space navbar-dark header-dark navbar-boxed header-reverse-scroll py-3">
            <div class="container nav-header-container">
                <div class="col-auto col-sm-4 col-lg-2 col-8 mr-auto pl-lg-0">
                    <a class="navbar-brand" href="/">
                        <img src={whiteLogo} alt="" class="img-fluid"/>
                    </a>
                </div>
                <div class="col-auto col-lg-4 text-right col-xl-4 text-right pr-0 font-size-0">
                    <div class="header-search-icon search-form-wrapper d-none d-sm-inline-block">
                        <a href={PHONE_HREF}  class="btn-secondary btn btn-rounded m-2 text-uppercase">
                            {PHONE} </a>
                        <a href="javascript:;" class="quoteForm btn-secondary btn btn-rounded text-uppercase" onClick={onOpenModal}>Request
                            Quote</a>
                    </div>
                </div>
            </div>
        </nav>
      </header>

      <section id="hero" class="py-5">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-8">
                      <p class="d-inline-block bg-accent fs-5 p-2 rounded-1">PROFESSIONAL BOOK PUBLISHING SERVICES</p>
                      <h1 class="fs-1 fw-bold">Want To Publish Your Book?</h1>
                      <h2 class="fw-bold fs-1">Let's Publish It Now!</h2>
                      <p>Nothing is more captivating than writing a story of doom that comes to mind, but the book
                          publishing process can be challenging. This is exactly why {WEBSITE_NAME} is here.
                          With
                          a team of publishers experienced in setting global trends and turning newbie authors into
                          bestsellers, we take our place in the author’s world.</p>
                      <ul class="checklist">
                          <li>Fastest Turnaround</li>
                          <li>Unlimited Revisions</li>
                          <li>Get Accepted To Amazon Fast</li>
                      </ul>
                      <ul class="list-inline" id="hero-trustlogos">
                          <li class="list-inline-item">
                              <a href="javascript:;" target="_blank"><img src={rating1} alt=""
                                      class="img-fluid"/></a>
                          </li>
                          <li class="list-inline-item">
                              <img src={rating2} alt="" class="img-fluid"/>
                          </li>
                          <li class="list-inline-item">
                              <img src={rating3} alt="" class="img-fluid"/>
                          </li>
                      </ul>
                      <div class="row mt-4 gy-2">
                          <div class="col-md-4">
                              <a href="javascript:;" class="btn btn-secondary w-100 rounded-pill btn-lg quoteForm" onClick={onOpenModal}>Get
                                  Started</a>
                          </div>
                          <div class="col-md-4">
                              <a href="javascript:;" class="btn btn-accent w-100 chat liveChatButton rounded-pill btn-lg" onClick={toggleChat}>Talk to Us</a>
                          </div>
                      </div>
                      <p class="fw-semibold my-4 fs-5">Or Give Us A Call <a href={PHONE_HREF} 
                              class="text-white">{PHONE} </a></p>
                  </div>
                  <div class="col-lg-4">
                      <div id="hero-form" class="rounded-4 overflow-hidden">
                          <div class="bg-accent py-4 px-2 text-center text-secondary">
                              <img src={logo} alt="Amazon Books Publishing Expert"/>
                          </div>
                          <div class="bg-secondary px-2 py-3 text-uppercase fs-6 text-center">
                              Up to 50% Off <br/>
                              Offer Valid for the First 8 Signups Only!
                          </div>
                          <div class="bg-light px-3 py-5">

                              <form class="form_submission main-pop w-90 mx-auto" method="POST" onSubmit={onSubmitFunc}>
                                  <div class="mb-3">
                                      <input type="text" name="name" placeholder="Name"
                                          class="form-control border-top-0 border-start-0 border-end-0 border-secondary outline-0 rounded-0"
                                          required onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                                  </div>
                                  <div class="mb-3">
                                      <input type="email" name="email" placeholder="Email"
                                          class="form-control border-top-0 border-start-0 border-end-0 border-secondary outline-0 rounded-0"
                                          required onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/>
                                  </div>
                                  <div class="mb-3">
                                      <input type="number" name="phone" placeholder="Phone" minlength="11"
                                          class="form-control border-top-0 border-start-0 border-end-0 border-secondary outline-0 rounded-0"
                                          maxlength="12" required onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/>
                                  </div>
                                  <div class="mb-3">
                                      <textarea name="message" id="msg" cols="30" rows="3" placeholder="Message"
                                          class="form-control border-top-0 border-start-0 border-end-0 border-secondary outline-0 rounded-0" onChange={(e) =>setFormData({ ...formData,message: e.target.value,})}></textarea>
                                  </div>
                                  <div class="text-center">
                                      
                                      
                                      {loading == true ? (
                                              <div className="loader" style="display: none">
                                                <img alt="loader" src={loader}/>
                                              </div>
                                          ) : (
                                            <button class="packageformsubmit1 btn btn-accent w-75 rounded-0" type="submit"
                                          name="submit" value="1">Get Started</button>
                                          )
                                      }
                                  </div>

                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="bg-accent py-4">
          <div class="container">
              <div id="platforms-slider">
                <PlatformSlider />
                  {/* <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform8}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform2}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform6}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform7}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform9}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform10}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform5}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform1}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform4}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class="img-fluid lazy" src={platform3}/></p>
                  </div> */}
              </div>
          </div>
      </section>

      <section class="py-5">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-md-6 order-md-2">
                      <img src={sec1} alt="" class="img-fluid mb-3 lazy"/>
                  </div>
                  <div class="col-md-6 order-md-1">
                      <h2 class="mb-3">Making Book Publishing Convenient For Authors!</h2>
                      <p class="mb-3">{WEBSITE_NAME} is a full-service book publishing company keen to help
                          authors create high-quality print or digital books that meet their vision at every stage of the
                          publishing journey. With comprehensive publishing, design, marketing, production, and
                          distribution services, our expert publishers support authors and maintain some of the industry’s
                          highest royalty rates. Reach out to us today, and witness how our team does wonders for you!</p>
                      <div class="row">
                          <div class="col-md-5">
                              <a href={PHONE_HREF} 
                                  class="btn btn-lg w-100 btn-secondary rounded-pill my-2">{PHONE} </a>
                          </div>
                          <div class="col-md-5">
                              <a href="javascript:;" class="btn btn-lg btn-accent w-100 chat liveChatButton  rounded-pill my-2" onClick={toggleChat}>Live
                                  Chat</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section id="cta-1" class="bg-accent text-white">
          <div class="container">
              <div class="row">
                  <div class="col-md-12 text-center py-5">
                      <p class="text-left fs-4 text-white fw-semibold">Are You An Indie Author And Doubtful That Your
                          Manuscript Is Good Enough To Be Published?</p>
                      <h3 class="fs-1 text-white fw-bold">Do Not Worry! If You Hire Us, Just Sit Back And Relax, As We Are
                          Book Publishing Experts.</h3>
                      <p class="mt-4">
                          <a href="javascript:;" class="btn btn-light btn-lg m-2 d-block d-md-inline-block quoteForm" onClick={onOpenModal}>Get
                              Started</a>
                          <a href={PHONE_HREF} 
                              class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block">{PHONE} </a>
                          <a href="javascript:;" class="btn btn-light btn-lg m-2 d-block d-md-inline-block chat liveChatButton " onClick={toggleChat}>Talk to Us</a>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <section id="how" class="grey-bg py-5 md-py-10">
          <div class="container">
              <div class="row text-center">
                  <div class="col-12">
                      <h2 class="text-secondary">A Perfect Book. A Dream Come True</h2>
                      <p>Our professional team makes every effort to make your book hit the shelves after working magic to
                          turn a simple manuscript into an extraordinary book. Please have a look at how our book
                          publishing process works.</p>
                  </div>
              </div>
                <StepSlider />
          </div>
      </section>

      <section id="cta-2" class="bg-accent text-secondary">
          <div class="container">
              <div class="row">
                  <div class="col-md-5 position-relative">
                      <img src={cta2} alt="" class="img-fluid cta-img lazy"/>
                  </div>
                  <div class="col-md-7 text-center py-5">
                      <p class="text-left text-white fs-4 fw-semibold">Don’t Let Your Idea Rot!</p>
                      <h3 class="fs-2 text-white fw-semibold">From Writing, Editing, And Designing To Publishing,
                          Marketing, And Everything Within, Our Experts Have Got It Covered!</h3>
                      <p class="mt-4">
                          <a href="javascript:;"
                              class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block quoteForm" onClick={onOpenModal}>Get
                              Started</a>
                          <a href={PHONE_HREF} 
                              class="btn btn-light btn-lg m-2 d-block d-md-inline-block">{PHONE} </a>
                          <a href="javascript:;" class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block chat liveChatButton " onClick={toggleChat}>Talk to
                              Us</a>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <section class="bg-grey py-5 md-py-10 text-center">
          <div class="container">
              <h2 class="text-secondary mb-3 fs-1 fw-semibold">Explore Our Book Publishing Services</h2>
              <div class="row pt-5" id="services">
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnGhostwriting} alt=""
                              class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnEditing} alt="" class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnProofreading} alt=""
                              class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnFormatting} alt=""
                              class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnCover} alt="" class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnPublishing} alt=""
                              class="img-fluid mx-auto lazy"/></a>
                  </div>
                  <div class="col px-2">
                      <a href="javascript:;"><img src={btnMarketing} alt=""
                              class="img-fluid mx-auto lazy"/></a>
                  </div>
              </div>
          </div>
      </section>

      <section id="cta-3" class="bg-accent text-white">
          <div class="container">
              <div class="row">
                  <div class="col-md-5 position-relative order-md-2">
                      <img src={cta3} alt="" class="img-fluid cta-img lazy"/>
                  </div>
                  <div class="col-md-7 text-center py-5 order-md-1">
                      <p class="text-left text-white fs-4 fw-semibold">Self-Publish Like a Pro!</p>
                      <h3 class="fs-1 text-white fw-bold">Get In Touch With Our Book Publishing Experts To Reveal Your Ode
                          To The World.</h3>
                      <p class="mt-4">
                          <a href="javascript:;" class="btn btn-light btn-lg m-2 d-block d-md-inline-block quoteForm" onClick={onOpenModal}>Get
                              Started</a>
                          <a href={PHONE_HREF} 
                              class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block">{PHONE} </a>
                          <a href="javascript:;" class="btn btn-light btn-lg m-2 d-block d-md-inline-block chat liveChatButton " onClick={toggleChat}>Talk to Us</a>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <section class="bg-grey py-5 md-py-10 text-center">
          <div class="container">
              <h2 class="fw-semibold fs-1 text-secondary">Why You Should Hire Us?</h2>
              <div class="row pt-5 gy-5">
                  <div class="col-md-4">
                      <img src={why1} alt="" class="img-fluid lazy"/>
                  </div>
                  <div class="col-md-4">
                      <img src={why2} alt="" class="img-fluid lazy"/>
                  </div>
                  <div class="col-md-4">
                      <img src={why3} alt="" class="img-fluid lazy"/>
                  </div>
                  <div class="col-md-4">
                      <img src={why4} alt="" class="img-fluid lazy"/>
                  </div>
                  <div class="col-md-4">
                      <img src={why5} alt="" class="img-fluid lazy"/>
                  </div>
                  <div class="col-md-4">
                      <img src={why6} alt="" class="img-fluid lazy"/>
                  </div>
              </div>
          </div>
      </section>

      <section id="cta-4" class="bg-accent text-white">
          <div class="container">
              <div class="row">
                  <div class="col-md-5 position-relative">
                      <img src={cta4} alt="" class="img-fluid cta-img lazy"/>
                  </div>
                  <div class="col-md-7 text-center py-5">
                      <p class="text-left text-white fs-4 fw-semibold">Do You Have Your Manuscript Ready? </p>
                      <h3 class="fs-2 text-white fw-semibold">It’s Time To Unveil It To The World With The Help Of Our
                          Book Publishing Experts!</h3>
                      <p class="mt-4">
                          <a href="javascript:;"
                              class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block quoteForm" onClick={onOpenModal}>Get
                              Started</a>
                          <a href={PHONE_HREF}  class="btn btn-light btn-lg m-2 d-block d-md-inline-block">
                              {PHONE} </a>
                          <a href="javascript:;" class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block chat liveChatButton " onClick={toggleChat}>Talk to
                              Us</a>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <section id="testimonial" class="bg-grey py-5 md-py-10 text-center">
          <div class="container">
              <div id="testimonials-slider">
                <TestimonialSlider />
                  {/* <div class="testimonial-slide">
                      <div class="row align-items-center">
                          <div class="col-md-6">
                              <img src={testimonial1} alt="" class="testimonial-img img-fluid lazy"/>
                          </div>
                          <div class="col-md-5">
                              <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                              <p><strong>Getting your book published is very…</strong></p>
                              <p>Getting your book published is very challenging. Finding the right publisher is even more
                                  so. I did extensive research Finding the one that met my needs. I finally settled on
                                  Amazon Books Publishing Expert. My project manager was Eminey. After working through some
                                  initial problems, with her assistance they produced a book of high quality. She did a
                                  fine job following up when needed. I would use them again.</p>
                              <p class="fw-bold">－ David Pearsall</p>
                          </div>
                      </div>
                  </div>
                  <div class="testimonial-slide">
                      <div class="row align-items-center">
                          <div class="col-md-6">
                              <img src={testimonial2} alt="" class="testimonial-img img-fluid lazy"/>
                          </div>
                          <div class="col-md-5">
                              <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                              <p><strong>solid experience</strong></p>
                              <p>Working with {WEBSITE_NAME} was an interesting experience. I had no idea what
                                  I
                                  was doing when I decided I wanted to publish. They streamlined the process and made it
                                  easy, there were a couple of snags but nothing major. I worked with Kevin, he was always
                                  available be i through email or phone call.</p>
                              <p>One thing id give them to improve would be to explain the process more, as a first timer
                                  I dint necessarily know what to ask it would've benefited me to actually know the
                                  process. Solid experience though</p>
                              <p class="fw-bold">－ Taurean</p>
                          </div>
                      </div>
                  </div>
                  <div class="testimonial-slide">
                      <div class="row align-items-center">
                          <div class="col-md-6">
                              <img src={testimonial3} alt="" class="testimonial-img img-fluid lazy"/>
                          </div>
                          <div class="col-md-5">
                              <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                              <p><strong>A guiding professional hand when it was needed.How do I get my stories ready for
                                      the…</strong></p>
                              <p>How do I get my stories ready for the public? How do I make sure they are told as I
                                  hoped? Can someone walk me through the steps and challenges so my hope is actualized? I
                                  turned to {WEBSITE_NAME} and met with the support and expertise I needed.
                              </p>
                              <p>Ken and his team listened to my book cover ideas and translated them to a cover that
                                  expressed the heart and spirit I sought to convey.</p>
                              <p>The {WEBSITE_NAME} team proofed the stories and brought from Title Page to
                                  Notes
                                  on the Author to the high professional standard I required.</p>
                              <p>Guidance on issues such as photo selection and quality expectations were available with a
                                  simple note or phone call.</p>
                              <p>Most important to me is that Ken made my nervous worries disappear with his clear
                                  guidance.</p>
                              <p>This support gives me confidence to write another.</p>
                              <p class="fw-bold">－ John W Gehring</p>
                          </div>
                      </div>
                  </div>
                  <div class="testimonial-slide">
                      <div class="row align-items-center">
                          <div class="col-md-6">
                              <img src={testimonial4} alt="" class="testimonial-img img-fluid lazy"/>
                          </div>
                          <div class="col-md-5">
                              <img src={stars} alt="" class="mb-3 mx-auto lazy"/>
                              <p><strong>I just received my book cover completed…</strong></p>
                              <p>I just received my book cover completed by Kevin Williams after just a couple days of
                                  ordering my package. Kevin did the most amazing cover design that I have ever seen on
                                  any book jacket before. I have used 7 other other companies before
                                  {WEBSITE_NAME} and I will never again use another company but
                                  {WEBSITE_NAME} again, they
                                  are truly amazing. I have been trying to get other designers to see my vision for twenty
                                  years and Kevin Williams nailed it and brought my vision to life. I am so pleased. Thank
                                  you {WEBSITE_NAME} for presenting opportunities of excellent quality work at
                                  budget prices during Americas recession. Thank you Kevin Williams for high quality and
                                  skilled artistic talent that honors my writing with a master piece cover presentation.
                                  Cheri Potter</p>
                              <p class="fw-bold">－ Cheri Potter</p>
                          </div>
                      </div>
                  </div> */}
              </div>
          </div>
      </section>

      <section id="cta-5" class="bg-accent text-white">
          <div class="container">
              <div class="row">
                  <div class="col-md-5 position-relative order-md-2">
                      <img src={cta5} alt="" class="img-fluid cta-img lazy"/>
                  </div>
                  <div class="col-md-7 text-center py-5 order-md-1">
                      <p class="text-left text-white fs-4 fw-semibold">Forget Traditional Publishing!</p>
                      <h3 class="fs-2 text-white fw-semibold">Collaborate With Our Book Publishing Experts To Self-Publish
                          Your Book On Major Literary Platforms!</h3>
                      <p class="mt-4">
                          <a href="javascript:;" class="btn btn-light btn-lg m-2 d-block d-md-inline-block quoteForm" onClick={onOpenModal}>Get
                              Started</a>
                          <a href={PHONE_HREF} 
                              class="btn btn-secondary btn-lg m-2 d-block d-md-inline-block">{PHONE} </a>
                          <a href="javascript:;" class="chat liveChatButton btn btn-light btn-lg m-2 d-block d-md-inline-block" onClick={toggleChat}>Talk to Us</a>
                      </p>
                  </div>
              </div>
          </div>
      </section>

      <footer>
          <div class="container">
              <div class="row">
                  <div class="col-md-4">
                      <div class="logo_footer_sec">
                          <img src={whiteLogo} alt="Amazon Books Publishing Expert" class="imgFluid"/>
                          <p>We are a trusted self publishing platform for writing, editing,
                              publishing,
                              and marketing your work online worldwide.
                          </p>

                      </div>
                  </div>
                  <div class="col-md-5 pl-5">
                      <div class="footer__info__detail">
                          <ul>
                              <li><span><img src={footerIcon1} alt="footer-icon"/></span><a
                                      href={PHONE_HREF} > {PHONE} </a></li>
                              <li><span><img src={footerIcon2} alt="footer-icon"/></span><a
                                      href={EMAIL_HREF} >{EMAIL} </a>
                              </li>
                              <li>
                                  <span><img src={footerIcon3} alt="footer-icon"/></span>
                                  <p>{ADDRESS} </p>
                              </li>
                          </ul>
                      </div>
                      
                  </div>
                  <div class="col-md-3">
                      <div class="logo_footer_sec">
                          <img src={sealsFooter} alt="Amazon Books Publishing Expert" class="img-fluid w-100"/>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default Home;
