import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { ThankYou } from './module/thankyou';
import { Cookie } from './module/cookie';

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/thankyou" element={<ThankYou />} />
    </Routes>
);

export default AppRoutes;
