import React from "react";
import Slider from "react-slick";

  import {
    loader,
    arrowLeft,
      arrowRight,
      btnCover,
      btnEditing,
      btnFormatting,
      btnGhostwriting,
      btnMarketing,
      btnProofreading,
      btnPublishing,
      cta2,
      cta3,
      cta4,
      cta5,
      facebook,
      favIcon,
      footerIcon1,
      footerIcon2,
      footerIcon3,
      heroBg,
      insta,
      linkedin,
      logoAmazon,
      logo,
      ptintest,
      rating1,
      rating2,
      rating3,
      sealsFooter,
      sec1,
      stars,
      testimonial1,
      testimonial2,
      testimonial3,
      testimonial4,
      tick,
      whiteLogo,
      why1,
      why2,
      why3,
      why4,
      why5,
      why6,
      you,
      platform1,
      platform2,
      platform3,
      platform4,
      platform5,
      platform6,
      platform7,
      platform8,
      platform9,
      platform10,
  } from '../../assets/images';

export default function PlatformSlider() {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  };
  return (
    <div className="slider-container">
        <Slider {...settings}>
                <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform8}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform2}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform6}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform7}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform9}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform10}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform5}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform1}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform4}/></p>
                  </div>
                  <div>
                      <p class="mx-3"><img alt="" class=" lazy" src={platform3}/></p>
                  </div>
        </Slider>
    </div>
    
  );
} 